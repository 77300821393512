export const ROSETTE_ITEMS = {
  Survey: { id: 1, label: 'Katıl', type: 'Survey' },
  VirtualVisit: { id: 2, label: 'İzle', type: 'VirtualVisit' },
  WeeklyPlayable: { id: 3, label: 'Oyna', type: 'WeeklyPlayable' },
  TopSeller: { id: 4, label: 'Top 3', type: 'TopSeller' },
  EOrder: { id: 5, label: 'Ön Sipariş', type: 'EOrder' },
  Chatbot: { id: 6, label: 'Şef Destek Hattı', type: 'Chatbot' },
  Content: { id: 7, label: 'İçeriğe Git', type: 'Content' },
  UserGuide: { id: 8, label: 'Videoya Git', type: 'UserGuide' },
  Academy: { id: 9, label: 'Başla', type: 'Academy' },
  EntrepreneurshipAcademy: { id: 10, label: 'Başla', type: 'EntrepreneurshipAcademy' },
  TobaccoAcademy: { id: 11, label: 'Başla', type: 'TobaccoAcademy' },
  DigitalAcademy: { id: 12, label: 'Başla', type: 'DigitalAcademy' },
  NpsSurvey: { id: 13, label: 'Katıl', type: 'NpsSurvey' },
  RegularSurvey: { id: 14, label: 'Katıl', type: 'RegularSurvey' },
};

export const ROSETTE_SUCCESSIBLE_TYPE = {
  Succesible: 1,
  UnSuccesible: 2,
  Succesful: 3,
  CurrentlyUnSuccesible: 4,
};

export const GTM_ROSETTE_EVENT_TYPE = {
  ECOMMERCE: 'ecommerce',
  SEF_DUKKANINDA: 'sef_dukkaninda',
  ON_SIPARIS_CLICK: 'on_siparis_click',
};

export const GTM_ROSETTE_LOCATION = {
  ROZETLERIM: 'rozetlerim',
};

export const RosetteQuizStatusType = {
  Started: 0,
  Successful: 1,
  UnSuccessful: 2,
  Answered: 3,
  Locked: 4,
  NotSolved: 5,
};

export const validQuizStatusTypes = [
  RosetteQuizStatusType.Started,
  RosetteQuizStatusType.UnSuccessful,
  RosetteQuizStatusType.Answered,
  RosetteQuizStatusType.NotSolved,
];

export const SurveyTakenStatusType = {
  Started: 0,
  Successful: 1,
  UnSuccessful: 2,
  Answered: 3,
};
